@font-face {
  font-family: kirbyss;
  src: url("kirby.c758712f.ttf");
}

* {
  letter-spacing: .1em;
  font-family: kirbyss, Arial, sans-serif;
  line-height: 1.5em;
}

body, html {
  color: #fff;
  background: linear-gradient(45deg, #232335, #141414);
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

h1, h2, h3 {
  font-family: kirbyss, Arial, sans-serif;
}

#wrapper {
  height: 100%;
  display: flex;
}

a {
  cursor: pointer;
  color: #a8a8f8;
  text-decoration: none;
  transition: color .3s, transform .3s;
  position: relative;
}

a:before {
  content: "";
  transform-origin: 100%;
  background: #a8a8f8;
  width: 100%;
  height: .1em;
  transition: transform .3s;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: scaleX(0);
}

a:hover {
  color: #c9c9ff;
  transform: scale(1.05);
}

a:hover:before {
  transform-origin: 0;
  transform: scaleX(1);
}

a:active, a:focus {
  color: #e1e1ff;
  outline: none;
}

.center {
  text-align: center;
}

button, .button {
  color: #e0e0e0;
  cursor: pointer;
  background-color: #535;
  border: none;
  border-radius: 5px;
  margin: 15px auto;
  padding: 8px 15px;
  font-size: 16px;
  font-weight: bold;
  transition: background-color .3s, transform .2s;
  display: block;
  box-shadow: 0 2px 5px #0003;
}

button:hover, .button:hover {
  background-color: #747;
  transform: scale(1.05);
}

button:active, .button:active {
  transform: scale(.95);
}

button:focus, .button:focus {
  outline: none;
  box-shadow: 0 0 0 2px #e0e0e0;
}

button:disabled, .button:disabled {
  color: #888;
  cursor: not-allowed;
  box-shadow: none;
  background-color: #444;
}

button:disabled:hover, button:disabled:active, .button:disabled:hover, .button:disabled:active {
  transform: none;
}

#wallet-modal {
  z-index: 1000;
  background-color: #000000b3;
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

.modal-content {
  background-color: #2c2c2c;
  border-radius: 10px;
  width: 80%;
  max-width: 400px;
  padding: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 20px #0000004d;
}

.wallet-options {
  padding: 0;
  list-style: none;
}

.wallet-options li {
  cursor: pointer;
  align-items: center;
  margin-bottom: 15px;
  display: flex;
}

.wallet-options img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

#walletconnect-option.button img {
  width: 24px;
  height: 24px;
}

#wallet-modal button {
  margin: 20px auto 0;
  display: block;
}

@media screen and (width <= 520px) {
  .wallet-options img {
    width: 20px;
    height: 20px;
  }

  #walletconnect-option.button img {
    width: 14px;
    height: 14px;
  }

  .modal-content {
    width: 100%;
    max-width: 100%;
    padding: 10px;
  }
}

body {
  overflow: hidden;
}

#canvas-container {
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  margin: 1rem;
  display: flex;
  position: relative;
  overflow: hidden;
}

canvas {
  position: relative;
}

.square-content {
  display: none;
}

#current-cost-container {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  display: flex;
}

#sidebar {
  color: #e0e0e0;
  background: linear-gradient(45deg, #232335, #141414);
  border-radius: 0 10px 10px 0;
  min-width: 230px;
  max-width: 230px;
  height: 100%;
  padding: 15px;
  overflow: hidden auto;
  box-shadow: -2px 0 5px #0000004d;
}

#sidebar #close-modal {
  display: none;
}

#sidebar .square-content {
  flex-direction: column;
  width: 100%;
}

#sidebar .pixel-card {
  background: linear-gradient(135deg, #22223c 0%, #2f2f4d 100%);
  border: 1px solid #0000004d;
  border-radius: 10px;
  margin-bottom: 15px;
  padding: 20px;
  transition: all .3s;
  box-shadow: 0 4px 6px #0000004d;
}

#sidebar .pixel-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 6px 10px #0000004d;
}

#sidebar .pixel-card .pixel-display-container {
  justify-content: center;
  align-items: center;
  margin: 0 auto 1rem;
  display: flex;
}

#sidebar .pixel-card .pixel-display-container .pixel-current, #sidebar .pixel-card .pixel-display-container .pixel-preview {
  border: 3px solid #333;
  width: 3rem;
  height: 3rem;
  transition: border .3s, transform .3s;
  box-shadow: 0 2px 4px #0003;
}

#sidebar .pixel-card .pixel-display-container .pixel-current:hover, #sidebar .pixel-card .pixel-display-container .pixel-preview:hover {
  border: 3px solid #333;
  transform: scale(1.1);
}

#sidebar .pixel-card .pixel-display-container .pixel-preview {
  background-color: #a8a8f81a;
  border: 3px solid #a8a8f8;
  margin-left: .5rem;
  transition: all .3s;
  display: none;
}

#sidebar .pixel-card .pixel-display-container .pixel-preview:hover {
  border-color: #c9c9ff;
}

#sidebar .pixel-card .pixel-properties {
  color: #e0e0e0;
}

#sidebar .pixel-card .pixel-properties p {
  text-align: center;
  margin: 5px 0;
  font-weight: 300;
}

#sidebar h1 {
  text-align: center;
  margin-bottom: 20px;
}

#address-text {
  align-items: center;
  display: flex;
}

#address-text #account-address {
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 140px;
  margin-right: 8px;
  overflow: hidden;
}

.purchase-card {
  background: linear-gradient(135deg, #3c2d50 0%, #433461 100%);
  border: 1px solid #0000004d;
  border-radius: 10px;
  margin-bottom: 15px;
  padding: 20px;
  transition: all .3s;
  box-shadow: 0 4px 6px #0000004d;
}

.purchase-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 6px 10px #0000004d;
}

.purchase-card h3, .purchase-card h4 {
  margin-bottom: 1rem;
  font-weight: 500;
}

.purchase-card #layer-slider {
  width: 100%;
  margin-bottom: 1rem;
}

.history-card {
  background: linear-gradient(135deg, #422d46 0%, #5a3457 100%);
  border: 1px solid #0000004d;
  border-radius: 10px;
  margin-bottom: 15px;
  padding: 20px;
  transition: all .3s;
  box-shadow: 0 4px 6px #0000004d;
}

.history-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 6px 10px #0000004d;
}

#color-options {
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  display: flex;
}

.color-option, input[type="color"] {
  cursor: pointer;
  appearance: none;
  background-color: #0000;
  border-radius: 50%;
  outline: none;
  width: 26px;
  height: 26px;
  transition: transform .3s, box-shadow .3s;
  box-shadow: 0 3px 6px #0000001a;
}

.color-option:hover, input[type="color"]:hover {
  transform: translateY(-3px);
  box-shadow: 0 5px 12px #0003;
}

.color-option::-webkit-color-swatch-wrapper {
  border-radius: 50%;
  padding: 0;
}

input[type="color"]::-webkit-color-swatch-wrapper {
  border-radius: 50%;
  padding: 0;
}

.color-option::-webkit-color-swatch {
  border: none;
  border-radius: 50%;
}

input[type="color"]::-webkit-color-swatch {
  border: none;
  border-radius: 50%;
}

.color-option::-moz-color-swatch-wrapper {
  border-radius: 50%;
}

input[type="color"]::-moz-color-swatch-wrapper {
  border-radius: 50%;
}

.color-option::-moz-color-swatch {
  border-radius: 50%;
}

input[type="color"]::-moz-color-swatch {
  border-radius: 50%;
}

#layer-slider {
  appearance: none;
  background: #fff3;
  border-radius: 5px;
  outline: none;
  width: 100%;
  height: 5px;
  margin-bottom: 1rem;
}

#layer-slider::-webkit-slider-thumb {
  appearance: none;
  cursor: pointer;
  background: #a8a8f8;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  transition: background .3s;
}

#layer-slider::-moz-range-thumb {
  cursor: pointer;
  background: #a8a8f8;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  transition: background .3s;
}

#layer-slider:hover::-webkit-slider-thumb {
  background: #c9c9ff;
}

#layer-slider:hover::-moz-range-thumb {
  background: #c9c9ff;
}

#layer-slider::-moz-focus-outer {
  border: 0;
}

.slider-controls {
  justify-content: center;
  align-items: center;
  gap: 10px;
  display: flex;
}

.slider-controls button {
  color: #e0e0e0;
  cursor: pointer;
  background-color: #535;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 1rem;
  transition: background-color .3s;
}

.slider-controls button:hover {
  background-color: #747;
}

.slider-controls div#slider-value {
  padding: 0 10px;
}

.new-value, #current-cost {
  color: #a8a8f8;
  background-color: #a8a8f81a;
  border: 1px solid #a8a8f8;
  border-radius: 5px;
  padding: 5px 10px;
  font-weight: 500;
  transition: all .3s;
}

.new-value:hover, #current-cost:hover {
  background-color: #c9c9ff33;
  border-color: #c9c9ff;
  transform: translateY(-2px);
  box-shadow: 0 2px 5px #0000001a;
}

#history {
  padding: 0;
  list-style: none;
  position: relative;
}

#history:before {
  content: "";
  background-color: #535;
  width: 2px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 50%;
}

#history li {
  align-items: center;
  margin: 20px 0;
  display: flex;
  position: relative;
}

#history li .color-circle {
  border: 2px solid #e0e0e0;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

#history li .purchase-info {
  background: #2a2a2a;
  border-radius: 5px;
  flex-grow: 1;
  padding: 5px 10px;
  box-shadow: 0 2px 5px #0003;
}

.pagination {
  justify-content: center;
  margin: 20px 0;
  display: flex;
}

.pagination button {
  color: #e0e0e0;
  cursor: pointer;
  background-color: #535;
  border: none;
  border-radius: 5px;
  margin: 0 5px;
  padding: 5px 10px;
  transition: background-color .3s;
}

.pagination button:hover {
  background-color: #777;
}

@media screen and (width >= 520px) and (width <= 720px) {
  #sidebar {
    min-width: 180px;
    max-width: 180px;
  }

  #sidebar .pixel-card .pixel-display-container .pixel-current, #sidebar .pixel-card .pixel-display-container .pixel-preview {
    border: 2px solid #333;
    width: 2.4em;
    height: 2.4em;
    padding: 0;
  }

  #sidebar .pixel-card .pixel-display-container .pixel-current:hover, #sidebar .pixel-card .pixel-display-container .pixel-preview:hover {
    border: 3px solid #333;
    transform: scale(1.1);
  }

  a, button, #history, #sidebar {
    font-size: .8em;
  }

  .color-option, input[type="color"] {
    width: 22px;
    height: 22px;
  }
}

@media screen and (width <= 520px) {
  #sidebar {
    background: linear-gradient(45deg, #232335cc, #141414cc);
    max-width: 100%;
    display: none;
  }

  #sidebar #close-modal {
    font-size: .5em;
    display: block;
  }

  .modal-content {
    z-index: 999;
    width: 100%;
  }

  .square-content {
    justify-content: center;
    display: flex;
  }

  .pixel-card, .history-card, .purchase-card {
    width: 230px;
    margin: 1em auto;
  }
}

#progress-bar {
  background-color: #232335;
  width: 80%;
  height: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
}

#progress-bar .progress-fill {
  background-color: #a8a8f8;
  width: 0%;
  height: 100%;
  transition: width .5s;
}

#loading-text {
  color: #a8a8f8;
  font-size: 16px;
  position: absolute;
  top: calc(50% + 30px);
  left: 50%;
  transform: translateX(-50%);
}

#notification-modal {
  z-index: 1001;
  background-color: #000000b3;
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

.notification-content {
  background-color: #2c2c2c;
  border-radius: 10px;
  width: 80%;
  max-width: 400px;
  padding: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 20px #0000004d;
}

#notification-modal button {
  margin: 20px auto 0;
  display: block;
}

::-webkit-scrollbar-track {
  background-color: #2c2c2c;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #535;
  border: 2px solid #4c2c4c;
  border-radius: 10px;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #777;
}

/*# sourceMappingURL=index.a3075f8a.css.map */
